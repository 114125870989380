import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_MEMBER_ID_MUTATION } from "../../utils/graphql/queries";
import UpdateUsernameModal from '../common/UpdateUsernameModal';

const CommunityInformation = ({ user }) => {
    const [forumLink, setForumLink] = useState(null);
    const [forumLinkText, setForumLinkText] = useState(null);
    const [instagramLink, setInstagramLink] = useState(null);
    const [instagramLinkText, setInstagramLinkText] = useState(null);
    const [facebookLink, setFacebookLink] = useState(null);
    const [facebookLinkText, setFacebookLinkText] = useState(null);
    const [updateUserMemberId] = useMutation(UPDATE_USER_MEMBER_ID_MUTATION);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [platform, setPlatform] = useState(null);

    useEffect(() => {
        if (user.discourseProfileUrl === null) {
            setForumLinkText('Login to setup');
            setForumLink(process.env.REACT_APP_DISCOURSE_URL);
        } else {
            setForumLink(user.discourseProfileUrl);
            setForumLinkText(user.discourseProfileUrl);
        }
        if (user.instagramUsername === null) {
            setInstagramLinkText('None set - Update to your Instagram username');
        } else {
            setInstagramLink("https://www.instagram.com/" + user.instagramUsername);
            setInstagramLinkText("@" + user.instagramUsername);
        }
        if (user.facebookUsername === null) {
            setFacebookLinkText('None set - Update to your Facebook username');
        } else {
            setFacebookLink("https://www.facebook.com/" + user.facebookUsername);
            setFacebookLinkText("https://www.facebook.com/" + user.facebookUsername);
        }
    }, [user]);

    const openModal = (platform) => {
        setPlatform(platform);
        setIsModalOpen(true);
    };

    const handleSave = async (username) => {
        const input = {
            userEmail: user.email, // Assuming you have user email in the user object
            [`${platform.toLowerCase()}Username`]: username
        };

        try {
            const { data } = await updateUserMemberId({
                variables: { input }
            });

            const updatedUser = data.updateUser.user;

            if (platform === 'Instagram') {
                setInstagramLink(`https://www.instagram.com/${updatedUser.instagramUsername}`);
                setInstagramLinkText(`@${updatedUser.instagramUsername}`);
            } else if (platform === 'Facebook') {
                setFacebookLink(`https://www.facebook.com/${updatedUser.facebookUsername}`);
                setFacebookLinkText(`https://www.facebook.com/${updatedUser.facebookUsername}`);
            }
        } catch (error) {
            console.error('Error updating username:', error);
        }
    };

    return (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-6 sm:px-6 flex justify-between items-center">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Community</h3>
            </div>
            <div className="border-t border-gray-200">
                <dl className="divide-y divide-gray-200">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-md font-semibold text-gray-900">Forums</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex justify-between">
                            <a href={forumLink} target="_blank" rel="noopener noreferrer" className="link">
                                {forumLinkText}
                            </a>
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-md font-semibold text-gray-900">Instagram</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex justify-between">
                            {instagramLinkText === 'None set - Update to your Instagram username' ? (
                                <button onClick={() => openModal('Instagram')} className="link">
                                    {instagramLinkText}
                                </button>
                            ) : (
                                <>
                                    <a href={instagramLink} target="_blank" rel="noopener noreferrer" className="link">
                                        {instagramLinkText}
                                    </a>
                                    <button onClick={() => openModal('Instagram')} className="link">
                                        Edit
                                    </button>
                                </>
                            )}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-md font-semibold text-gray-900">Facebook</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex justify-between">
                            {facebookLinkText === 'None set - Update to your Facebook username' ? (
                                <button onClick={() => openModal('Facebook')} className="link">
                                    {facebookLinkText}
                                </button>
                            ) : (
                                <>
                                    <a href={facebookLink} target="_blank" rel="noopener noreferrer" className="link">
                                        {facebookLinkText}
                                    </a>
                                    <button onClick={() => openModal('Facebook')} className="link">
                                        Edit
                                    </button>
                                </>
                            )}
                        </dd>
                    </div>
                </dl>
            </div>
            <UpdateUsernameModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={handleSave}
                platform={platform}
            />
        </div>
    )
}

export default CommunityInformation;