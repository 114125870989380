import { formatDate } from "../../utils/dateUtils";
const ProfileAccountInformation = ({ user }) => {
    return (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-6 sm:px-6">
                <div className="mb-3">
                    <img class="w-24 h-24 mb-3 rounded-full shadow-lg" src={user.discourseId ? user.discourseAvatarUrl : '/default-avatar.png'} alt="image" />
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">{user.firstName} {user.lastName}</h2>
                </div>
                <div className="mb-3">
                    <label>Member ID</label>
                    <p>{user.memberId}</p>
                </div>

                <div className="mb-3">
                    <label>Membership Level</label>
                    <p>Founding</p>
                </div>

                <div className="mb-3">
                    <label>Member since</label>
                    <p>{formatDate(user.createdAt)}</p>
                </div>

                {user.discourseProfileUrl !== null &&
                    <div className="mb-3">
                        <label>Discourse Profile</label>
                        <a href={user.discourseProfileUrl} target="_blank" rel="noopener noreferrer" className="link">
                            {user.discourseProfileUrl}
                        </a>
                    </div>
                }

                {user.instagramUsername !== null &&
                    <div className="mb-3">
                        <label>Instagram</label>
                        <a href={`https://www.instagram.com/${user.instagramUsername}`} target="_blank" rel="noopener noreferrer" className="link">
                            @{user.instagramUsername}
                        </a>
                    </div>
                }

                {user.facebookUsername !== null &&
                    <div className="mb-3">
                        <label>Facebook</label>
                        <a href={`https://www.facebook.com/${user.facebookUsername}`} target="_blank" rel="noopener noreferrer" className="link">
                            {`https://www.facebook.com/${user.facebookUsername}`}
                        </a>
                    </div>
                }
            </div>
        </div>
    )
}

export default ProfileAccountInformation;