import { CheckIcon, ChevronLeftIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import backgroundImage from '../../assets/images/plans-banner.png';
import MemberIdForm from '../../components/plans/MemberIdForm';

const tiers = [
  {
    name: 'Free',
    id: 'free',
    href: '#',
    price: '',
    available: true,
    oneTime: true,
    features: [
      'Get your PCSL Member Number', 
      'Digital membership card provided', 
      'Access to only Level 1 & 2 matches', 
      'First 8 matches tracked by PCSL Rating System', 
      'Limited access to the PCSL Forums'],
    buttonMessage: 'Current'
  },
  {
    name: 'Standard',
    id: 'standard',
    href: '#',
    price: '$100',
    available: false,
    oneTime: false,
    features: [
      'Get your PCSL Member Number', 
      'Digital membership card provided', 
      'Access to PCSL matches of all levels', 
      'All matches tracked by PCSL Rating System', 
      'Full access to the PCSL Forums'
    ],
    buttonMessage: 'Available in 2025'
  },
  {
    name: 'Founding',
    id: 'founding',
    href: '#',
    price: '$1000',
    available: true,
    oneTime: true,
    features: [
      'Available only through 2025',
      'Early lifetime membership option to support PCSL as we develop',
      'Opportunity to choose a unique PCSL Member Number',
      'Limited edition founding membership display plaque',
      'Early registration option for Level 3 & 4 matches through 2030',
      'Access to PCSL matches of all levels',
      'All matches tracked by PCSL Rating System',
      'Full access to the PCSL Forums'
    ],
    buttonMessage: 'Upgrade'
  },
  {
    name: 'Lifetime',
    id: 'lifetime',
    href: '#',
    price: '',
    available: false,
    oneTime: true,
    features: [
      'Not available until 2026',
      'Get your PCSL Member Number',
      'Receive physical lifetime membership card',
      'Access to PCSL matches of all levels',
      'All matches tracked by PCSL Rating System',
      'Full access to the PCSL Forums'
    ],
    buttonMessage: 'Available in 2026'
  },
];

const renderButton = (tier) => {
  if (tier.id === 'free') {
    return (
      <a href="#" aria-describedby={tier.id} className="mt-8 btn-primary" disabled={true}>
        {tier.buttonMessage}
      </a>
    );
  } else if (tier.available && tier.price !== '') {
    return (
      <Link to={`/plans/${tier.id}/checkout`} aria-describedby={tier.id} className="mt-8 btn-primary">
        {tier.buttonMessage}
      </Link>
    );
  } else {
    return (
      <a href="#" aria-describedby={tier.id} className="mt-8 btn-disabled" disabled={true}>
        {tier.buttonMessage}
      </a>
    );
  }
}

export default function PlansPage() {
  return (
    <div className="isolate overflow-hidden bg-gray-900 bg-no-repeat bg-center " style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
      <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            PCSL Membership <br className="hidden sm:inline lg:hidden" />
          </p>
        </div>
      </div>
      <div className="flow-root bg-white pb-24 sm:pb-32">
        <div className="-mt-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="absolute top-4 left-4">
                <Link to="/dashboard" className="flex items-center font-semibold text-md text-gray-300 hover:underline">
                  <ChevronLeftIcon className="h-5 w-5 mr-1" />
                  <span>Dashboard</span>
                </Link>
              </div>
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    <h3 id={tier.id} className="text-base font-semibold leading-7 text-blue-800">
                      {tier.name}
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.price}</span>
                      <span className="text-base font-semibold leading-7 text-gray-600">
                        {tier.price !== '' ? (tier.oneTime ? "one-time" : "/year") : null}
                      </span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">{tier.description}</p>
                    <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon className="h-6 w-5 flex-none text-blue-800" aria-hidden="true" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {renderButton(tier)}
                </div>
              ))}
              <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-4 lg:flex-row lg:items-center">
                <div className="lg:min-w-0 lg:flex-1">
                  <h3 className="text-lg font-semibold leading-8 tracking-tight text-blue-800">Secure your Member ID</h3>
                  <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>As a Founding Member, you can pick your own member ID number between 1 and 5 digits</p>
                  </div>
                  <MemberIdForm />
                </div>
                <Link to="/plans/founding/checkout" className="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-blue-800 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Upgrade <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
