import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/FirebaseAuthContext';
import { Link, useNavigate } from 'react-router-dom';

const initialSteps = [
    { id: 0, name: 'Intro', path: '', imgLink: '/assets/images/survey-intro-cover.jpg' },
    { id: 1, name: 'Personal Fulfillment', path: 'personal-fulfillment', imgLink: '/assets/images/survey-personal-fulfillment-cover.jpg' },
    { id: 2, name: 'Love of the Game', path: 'love-of-the-game', imgLink: '/assets/images/survey-love-of-the-game-cover.jpg' },
    { id: 3, name: 'Community & Camaraderie', path: 'community-camaraderie', imgLink: '/assets/images/survey-community-camaraderie-cover.jpg' },
    { id: 4, name: 'Practical Application', path: 'practical-application', imgLink: '/assets/images/survey-practical-application-cover.jpg' },
    { id: 5, name: 'Achieving Greatness', path: 'achieving-greatness', imgLink: '/assets/images/survey-achieving-greatness-cover.jpg' },
    { id: 6, name: 'Results', path: 'results', imgLink: '/assets/images/survey-results-cover.jpg' },
];

export default function StartPersonalitySurveyPage() {
    const navigate = useNavigate();
    const { userLoggedIn, firebaseUser } = useAuth();

    useEffect(() => {
        if (!userLoggedIn) {
            navigate('/login');
        }
    }, [userLoggedIn, firebaseUser, navigate]);

    const handleSubmit = () => {
        navigate('/personality-survey/personal-fulfillment');
    };

    return (
        <div className="flex flex-col h-full">
            <nav aria-label="Progress">
                <ol role="list" className="divide-y divide-gray-300 border border-gray-300 md:flex md:divide-y-0">
                    {initialSteps.map((step, index) => (
                        <li
                            key={step.name}
                            className="relative md:flex md:flex-1 cursor-pointer group"
                            onClick={() => navigate(`/personality-survey/${step.path}`)}
                        >
                            <div className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                                <span
                                    className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ${index === 0 ? 'border-blue-800 text-blue-800' : 'border-gray-300 text-gray-500 group-hover:border-blue-800 group-hover:text-blue-800'
                                        }`}
                                >
                                    {step.id}
                                </span>
                                <span
                                    className={`ml-4 text-sm font-medium ${index === 0 ? 'text-blue-800' : 'text-gray-500 group-hover:text-blue-800'
                                        }`}
                                >
                                    {step.name}
                                </span>
                            </div>
                            {step.id !== initialSteps.length - 1 && (
                                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                                    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                                        <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>

            <div className="w-full h-full">
                <div className="flex flex-col-reverse md:flex-row items-center md:items-start">
                    <div className="md:w-1/2 w-full relative">
                        <img
                            src={initialSteps[0].imgLink}
                            className="w-full h-auto"
                        />
                        <div className="absolute top-0 left-0 p-4">
                            <Link to="/dashboard" className="flex items-center font-semibold text-md text-white hover:underline">
                                <ChevronLeftIcon className="h-5 w-5 mr-1" />
                                <span>Dashboard</span>
                            </Link>
                        </div>
                    </div>
                    <div className="md:w-1/2 w-full pt-4 px-6">
                        <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Competition Shooter Personality Survey
                        </h1>
                        <p className="text-base sm:text-md md:text-lg lg:text-xl">
                            Shooters find satisfaction through participating in competitive shooting for a variety of reasons. Below are five distinct categories that shooters may relate to in varying degrees. How much an individual relates to these categories can be very interesting and informative about what is most important to them in a competitive shooting format. Understanding what is important to others provides more clarity and respect within the community and leads to more productive discussions about fine tuning the details of PCSL, or any other format. Read each category’s description, and rate each between 1-10 depending on how much your interest in competitive shooting is influenced by that aspect. 1 means the aspect is totally unrelatable and plays no role in why you find satisfaction within competitive shooting. 10 means the aspect is extremely important to you and you relate to it in every way described. Treat each category independently - if both #2 and #3 are reasons that you feel are equally important to you, rate them both the same, even if they are both 10s.
                        </p>
                        <button type="submit" className="text-center btn-primary my-4" onClick={handleSubmit}>
                            Start
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}