import { useNavigate } from 'react-router-dom';

const ViewProfile = ({ user }) => {    
    const navigate = useNavigate();

    const handleViewProfile = () => {
        navigate(`/members/${user.memberId}`);
    };

    return (
        <div className="bg-white shadow sm:rounded-lg p-4 sm:p-6">
            <div className="flex flex-col items-center pb-10">
                <img className="w-24 h-24 mb-3 rounded-full shadow-lg" src={user.discourseId ? user.discourseAvatarUrl : '/default-avatar.png'} alt="Profile" />
                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{user.name}</h5>
                <div className="flex flex-col items-center space-y-2 mt-4 md:space-y-0 md:flex-row md:space-x-2">
                    <button type="button" onClick={handleViewProfile} className="w-full md:w-auto text-center btn-primary">
                        View Profile
                    </button>
                    <a href={user.discourseId ? user.discourseProfileUrl : process.env.REACT_APP_DISCOURSE_URL} className="w-full md:w-auto py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        Upload Photo
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ViewProfile;