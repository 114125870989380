import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useAuth } from '../../../contexts/FirebaseAuthContext';
import { GET_SURVEY_RESULTS_BY_USER_ID_QUERY, GET_USER_BY_EMAIL_QUERY } from '../../../utils/graphql/queries';
import CompetitorPersonalityChart from '../../../components/profile/CompetitorPersonalityChart';

const initialSteps = [
    { id: 0, name: 'Intro', path: '', imgLink: '/assets/images/survey-intro-cover.jpg' },
    { id: 1, name: 'Personal Fulfillment', path: 'personal-fulfillment', imgLink: '/assets/images/survey-personal-fulfillment-cover.jpg' },
    { id: 2, name: 'Love of the Game', path: 'love-of-the-game', imgLink: '/assets/images/survey-love-of-the-game-cover.jpg' },
    { id: 3, name: 'Community & Camaraderie', path: 'community-camaraderie', imgLink: '/assets/images/survey-community-camaraderie-cover.jpg' },
    { id: 4, name: 'Practical Application', path: 'practical-application', imgLink: '/assets/images/survey-practical-application-cover.jpg' },
    { id: 5, name: 'Achieving Greatness', path: 'achieving-greatness', imgLink: '/assets/images/survey-achieving-greatness-cover.jpg' },
    { id: 6, name: 'Results', path: 'results', imgLink: '/assets/images/survey-results-cover.jpg' },
];

export default function EndPersonalitySurveyPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const providedAnswers = location.state?.providedAnswers || [];
    const { userLoggedIn, firebaseUser } = useAuth();
    const [user, setUser] = useState(null);
    const [surveyAnswers, setSurveyAnswers] = useState(null);

    const handleSubmit = () => {
        navigate('/dashboard');
    };

    const { data: userData } = useQuery(GET_USER_BY_EMAIL_QUERY, {
        variables: { email: firebaseUser.email },
        skip: providedAnswers.length > 0 || !userLoggedIn,
        onCompleted: (data) => {
            setUser(data.getUserByEmail);
        },
    });

    useEffect(() => {
        if (providedAnswers.length !== 0 && !user) {
            setSurveyAnswers(providedAnswers);
        }
    }, [providedAnswers, user]);

    // Fetch survey results when userData is available
    const [getSurveyResultsByUserId] = useLazyQuery(GET_SURVEY_RESULTS_BY_USER_ID_QUERY, {
        onCompleted: (data) => {
            const answers = data.getSurveyResultsByUserId.map(result => parseFloat(result.answer.content));
            setSurveyAnswers(answers);
        }
    });

    useEffect(() => {
        if (user) {
            getSurveyResultsByUserId({
                variables: { userId: parseInt(user.id), surveyName: 'Competition Shooter Personality' },
            });
        }
    }, [user, getSurveyResultsByUserId]);

    return (
        <div className="flex flex-col h-full">
            <nav aria-label="Progress">
                <ol role="list" className="divide-y divide-gray-300 border border-gray-300 md:flex md:divide-y-0">
                    {initialSteps.map((step, index) => (
                        <li
                            key={step.name}
                            className="relative md:flex md:flex-1 cursor-pointer group"
                            onClick={() => navigate(`/personality-survey/${step.path}`)}
                        >
                            <div className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                                <span
                                    className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ${index === 6 ? 'border-blue-800 text-blue-800' : 'border-gray-300 text-gray-500 group-hover:border-blue-800 group-hover:text-blue-800'
                                        }`}
                                >
                                    {step.id}
                                </span>
                                <span
                                    className={`ml-4 text-sm font-medium ${index === 6 ? 'text-blue-800' : 'text-gray-500 group-hover:text-blue-800'
                                        }`}
                                >
                                    {step.name}
                                </span>
                            </div>
                            {step.id !== initialSteps.length - 1 && (
                                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                                    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                                        <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>

            <div className="w-full h-full">
                <div className="flex flex-col-reverse md:flex-row items-center md:items-start">
                    <div className="md:w-1/2 w-full relative">
                        <img
                            src={initialSteps[6].imgLink}
                            className="w-full h-auto"
                        />
                        <div className="absolute top-0 left-0 p-4">
                            <Link to="/dashboard" className="flex items-center font-semibold text-md text-white hover:underline">
                                <ChevronLeftIcon className="h-5 w-5 mr-1" />
                                <span>Dashboard</span>
                            </Link>
                        </div>
                    </div>
                    <div className="md:w-1/2 w-full pt-4 px-6">
                        <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                            Results
                        </h1>
                        <p className="text-base sm:text-md md:text-lg lg:text-xl">
                            Thank you for completing the Competition Shooting Personality Survey. Here are the results based on your answers. Shooters find satisfaction in competitive shooting for various reasons. Here are five distinct categories that shooters may identify with to different extents. Understanding how much an individual relates to these categories can reveal what aspects are most important to them in competitive shooting. This insight fosters clarity and respect within the community, leading to more productive discussions about refining the details of PCSL or other formats. A rating of 1 means the aspect is completely unrelatable, while a rating of 10 means the aspect is extremely important and you relate to it fully.
                        </p>
                        <div className="max-w-lg">
                            {surveyAnswers && <CompetitorPersonalityChart userAnswers={surveyAnswers} /> }
                        </div>
                        <button type="submit" className="text-center btn-primary my-4" onClick={handleSubmit}>
                            Finish
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
