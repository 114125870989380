import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/FirebaseAuthContext';

export default function SignupSuccessPage() {
    const { userLoggedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userLoggedIn) {
            navigate('/login');
        }
    }, [userLoggedIn, navigate]);

    const handleSubmit = () => {
        navigate('/personality-survey');
    };

    return (
        <div className="relative w-full h-screen flex flex-col lg:flex-row items-center justify-center bg-white">
            <div className="absolute top-0 left-0 p-4 w-32 h-auto">
                <Link to="/">
                    <img src='/assets/images/pcsl-logo-letters-only.png' alt="Logo" className="w-full h-auto" />
                </Link>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col items-center justify-center p-4 bg-white">
                <div className="w-full max-w-md mt-4 p-4">
                    <h1 className="text-3xl font-semibold">Confirm your email address</h1>
                    <p className="mt-4 text-gray-500">
                        Thank you for signing up for PCSL! We've sent a confirmation email to your email address. Please confirm your email address by clicking the link in the email.
                    </p>
                    <button type="submit" className="text-center btn-primary mt-4" onClick={handleSubmit}>
                        Take Competition Shooting Personality Survey
                    </button>
                    <div className="mt-2">
                        <Link
                            to="/dashboard"
                            class="link"
                        >
                            Go to Dashboard
                        </Link>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block lg:w-1/2 h-full bg-gray-200 items-center justify-center">
                <div
                    className="w-full h-full bg-cover bg-center bg-no-repeat"
                    style={{ backgroundImage: `url('/assets/images/sign-in-cover.jpg')` }}
                />
            </div>
        </div>

    );
}